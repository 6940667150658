import axios from 'axios'
import { Modal, message } from 'antd'
import _ from 'lodash'
import * as np from 'nprogress'
import { loadStore, getType, setStore, storeKey,isMobile } from './util'
import '../globalConst/globalConst'

np.configure({ speed: 500 });


axios.defaults.baseURL = window.baseUrl;
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
function fetchData(url, options, method = 'GET', requestType = 'json', props) {
    if (url && url.indexOf('/investment') !== -1) {
        axios.defaults.baseURL = window.fundUrl;
    } else {
        axios.defaults.baseURL = window.baseUrl
    }

    if (requestType == 'form') {
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    let headers = {
        'Content-Type': requestType == 'form' ? 'application/x-www-form-urlencoded' : 'application/json',
    }

    let token = loadStore('token')
    if (token) {
        headers['Authorization'] = token
    }

    np.start();
    let config = {
        url,
        method,
        headers,
        timeout: 100000,
        validateStatus: function (status) {
            return status >= 200 && status < 500
        }
    }
    if (method.toLowerCase() === 'get') {
        config = {
            ...config,
            params: options
        }
    } else {
        config = {
            ...config,
            data: options
        }
    }

    // axios.interceptors.request.use(config => {
    //   // Do something before request is sent
    //   
    //   return config
    // }, error => {
    //   // Do something with request error
    //   return Promise.reject(error)
    // })
    let f = window.location.hash.substr(2);
    let that = this
    return axios(config)
        .then(data => {
            np.done()
            // let shouldLogin = true
            // if (url.indexOf('scanCodeEvaluateInfo') !== -1
            //     || url.indexOf('scanCodeSignIn') !== -1
            //     || url.indexOf('scanCodeAdd') !== -1
            //     || url.indexOf('sendMsg') !== -1
            //     || url.indexOf('verifyCode') !== -1
            //     || url.indexOf('h5EvaluateInfo') !== -1
            //     || url.indexOf('newH5Add') !== -1
            //     || url.indexOf('doubleWeekCompInfo') !== -1
            //     || url.indexOf('QRCodeProjectDetailInfo') !== -1
            // ) {//路演H5不自动跳转登录
            //     shouldLogin = false
            // }
            if (data.data.code === 401) {
                window.localStorage.removeItem(storeKey)
                if(isMobile()){
                    window.location.href = `/#/m/login`
                }else{
                    window.location.href = `/#/login`
                }
                
                setTimeout(() => {
                    window.location.reload()
                }, 200)

            }
            let result = _.cloneDeep(data.data)
            if (getType(result) === 'string') {
              result = {}
              result.data = data.data
            }
            // if(result.code && result.code !== 200){
            //   message.error(result.msg||'服务端错误，请联系管理员')
            // }
            return Promise.resolve(result)
        })
        .catch(e => {
            np.done()
            if (e.message !== 'out route') {
                message.error(e.message)
                // 
            }
            return Promise.reject(e)
        })
}

/**
 * GET请求
 * @param url 请求地址
 * @param options 请求参数
 * @param requestType 请求类型
 */
export function GET(url, options, requestType = 'json') {
    return fetchData(url, options, 'GET', requestType)
}

/**
 * POST请求
 * @param url 请求地址
 * @param options 请求参数
 * @param requestType 请求类型
 */
export function POST(url, options, requestType = 'json') {
    return fetchData(url, options, 'POST', requestType)
}

export function PUT(url, options, requestType = 'json') {
    return fetchData(url, options, 'PUT', requestType)
}

export function DELETE(url, options) {
    return fetchData(url, options, 'DELETE')
}

//上传文件
export function UPLOADFILES(url, formData, that) {
    // let token_exprtited_at = loadStore('token_exprited_at');
    //
    // let currentTime = (new Date()).getTime();
    // if (currentTime > token_exprtited_at) {
    //     setStore('token', '');
    // }
    // let localAuth = loadStore('token');
    // let f = window.location.hash.substr(2);
    // if (!localAuth && f.indexOf('login') !== 0) {
    //     // 
    //     return Promise.resolve({status: 500})
    // }
    np.start();
    let headers = {
        'Content-Type': 'multipart/form-data'
    }
    let token = loadStore('token')
    if (token) {
        headers['Authorization'] = token
    }
    let config = {
        url,
        method: 'POST',
        headers,
        timeout: 100000,
        validateStatus: function (status) {
            return status >= 200 && status < 500
        }
    }
    config = {
        ...config,
        data: formData,
        onUploadProgress: (evt) => {
            let fileProgress = parseInt((evt.loaded / evt.total) * 100) // 对原生进度事件的处理
            that && that.setState({
                fileProgress,
                // isShowUploadProgress: uploadProgress < 100
            })
        }
    }

    return axios(config)
        .then(data => {
            np.done()
            let result = _.cloneDeep(data.data)
            if (data.status >= 400) {
                message.error(result.message || `发生错误：${data.status}`)
            }
            return Promise.resolve(result)
        })
        .catch(e => {
            np.done()
            if (e.message !== 'out route') {
                message.error(e.message)
            }
            return Promise.reject(e)
        })
}

export const dict = {
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'dot': 'application/msword',
    'dotx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'pdf': 'application/pdf',
    'txt': 'text/plain',
    'gif': 'image/gif',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'png': 'image/png',
    'css': 'text/css',
    'html': 'text/html',
    'htm': 'text/html',
    'xsl': 'text/xml',
    'xml': 'text/xml',
    'mp4': 'video/mp4',
    'mpg4': 'video/mp4',
    'mpeg': 'video/mpeg',
    'mpg': 'video/mpeg',
    'avi': 'video/x-msvideo',
    'movie': 'video/x-sgi-movie',
    'bin': 'application/octet-stream',
    'exe': 'application/octet-stream',
    'so': 'application/octet-stream',
    'dll': 'application/octet-stream',
    'ai': 'application/postscript',
    'dir': 'application/x-director',
    'js': 'application/x-javascript',
    'swf': 'application/x-shockwave-flash',
    'xhtml': 'application/xhtml+xml',
    'xht': 'application/xhtml+xml',
    'zip': 'application/zip',
    // 'rar': 'application/x-rar-compressed',
    'rar': 'application/zip',
    '7z': 'application/x-7z-compressed',
    'mid': 'audio/midi',
    'midi': 'audio/midi',
    'mp3': 'audio/mpeg',
    'rm': 'audio/x-pn-realaudio',
    'rpm': 'audio/x-pn-realaudio-plugin',
    'wav': 'audio/x-wav',
    'bmp': 'image/bmp',
    'webp': 'image/webp',
    'webm': 'video/webm',
    'weba': 'audio/webm',
    'vsd': 'application/vnd.visio',
    'rtf': 'application/rtf',
    'oga': 'audio/ogg',
    'ogv': 'video/ogg',
    'ogx': 'application/ogg',
    'bz': 'application/x-bzip',
    'bz2': 'application/x-bzip2',
    'aac': 'audio/aac',
    'abw': 'application/x-abiword',
    'arc': 'application/x-freearc',
    'azw': 'application/vnd.amazon.ebook',
}
export function DOWNLOADFILE(url, name = '', suffix, that) {
    suffix = suffix || extName(name)
    return new Promise((resolve, reject) => {
        let fileType = dict[suffix] || 'application/msword'
        let token = loadStore('token')
        console.log(url.indexOf('http')==-1,url)
        axios.request({
            url:url.indexOf('http')==-1? url:"file/download?filename=" + encodeURI(url) + "&delete=" + true,
            // url: (url.indexOf('https://') !== -1 || url.indexOf('http://') !== -1) ? url : window.baseUrl + url,
            responseType: 'blob',
            method: 'get',
            headers: {
                Authorization: token || ''
            },
            onDownloadProgress: (evt) => {
                let progress = parseInt((evt.loaded / evt.total) * 100) // 对原生进度事件的处理

                that && that.setState({
                    progress,
                    visible: progress >= 100 ? false : true
                })
            }
        }).then(res => {
            if (!res) {
                reject()
                return
            }
            resolve()
            let url = window.URL.createObjectURL(new Blob([res.data],
                { type: fileType }))
            let a = document.createElement('a')
            a.href = url
            let decode = decodeURI(name, 'UTF-8')
            a.setAttribute('download', decode)
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(a.href)
            document.body.removeChild(a)
        })
    })


}

function extName(filename){
    if (!filename || typeof filename != 'string') {
        return null
    };
    let a = filename.split('').reverse().join('');
    let b = a.substring(0, a.search(/\./)).split('').reverse().join('');
    return b
};

