import { loadStore, setStore, storeKey } from '@/utils/util'
const marketObj = loadStore('market')
const initialState = {
    market: {
        type: '',
        ...marketObj,
    },
};

export default function user(prestate = initialState, action) {
    const { type, data } = action;
    switch (type) {
        case 'market':
            setStore('market', data)
            prestate.market = { ...data }
            return prestate;
        default:
            return prestate;
    }
}