if (process.env.NODE_ENV == 'development') {
    // window.baseUrl = 'http://10.8.0.6:8080'
    // window.baseUrl = 'http://172.20.0.1:8080' // 李飞本地
    // window.baseUrl = 'http://172.20.0.92:8080' // 李文浩本地
    window.baseUrl = 'https://www.ccftis.net/xyd' // 测试环境
    window.fundUrl = 'https://ccvcpetest.ccfin.tech'
} else {
    switch (process.env.REACT_APP_ENV) {
        case 'development':
            window.baseUrl = 'https://www.ccftis.net/xyd'
            window.fundUrl = 'https://www.ccvcpe.net'
            break;
        case 'production':
            window.baseUrl = 'http://47.94.192.230:881/xyd'
            window.fundUrl = 'https://www.ccvcpe.net'
            break;
        default:
            window.baseUrl = 'https://www.ccftis.net/xyd'
            window.fundUrl = 'https://www.ccvcpe.net'
    }
}