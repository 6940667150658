import _ from 'lodash';
import { message } from 'antd';
import CryptoJS from 'crypto-js'
import Hashes from 'jshashes'

import { API } from './API'
import { GET, POST } from './service'

export const classname = arr => arr.join(' ')
export const getType = obj => {
    let str = Object.prototype.toString.call(obj)
    return str.substr(1, str.length - 2).split(' ')[1].toLowerCase()
}
export function isTel(s) {
    var reg = /^(\d{3,4})?\d{7,8}$/;
    if (!reg.test(s)) {
        return false
    }
    return true
}
export function isPhone(s) {
    var reg = /^1[356789]\d{9}$/;
    if (!reg.test(s)) {
        return false
    }
    return true
}
export const isArray = obj => Object.prototype.toString.call(obj) === '[object Array]'
export const hideTel = tel => tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3')
export const pwdReg = /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/

export const isEmptyObj = obj => {
    for (let _ in obj) {
        return 0
    }
    return 1
}

export const getRouteParams = props => {
    const { location } = props
    let { state } = location
    return !state ? {} : state
}

/**
 * 数组格式转树状结构
 * @param   {array}     array
 * @param   {String}    id
 * @param   {String}    pid
 * @param   {String}    children
 * @return  {Array}
 */
export const arrayToTree = (data, id = 'id', pid = 'pid', children = 'children') => {
    let cpData = _.cloneDeep(data)
    cpData.sort((a, b) => a.order - b.order)
    let result = []
    let idData = {}

    cpData.forEach(item => {
        idData[item[id]] = item
    })

    cpData.forEach(item => {
        let pidData = idData[item[pid]]

        if (!pidData) {
            result.push(item)
        } else {
            if (!pidData[children]) {
                pidData[children] = []
            }
            pidData[children].push(item)
        }
    })

    return result
}

/**
 * 将对象转成 a=1&b=2的形式
 * @param obj 对象
 */
export function obj2String(obj, arr = [], idx = 0) {
    for (let item in obj) {
        arr[idx++] = [item, obj[item]]
    }
    return new URLSearchParams(arr).toString()
}

// export const queryString = name => {
//   let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
//   let r = window.location.search.substr(1).match(reg)
//   return r ? unescape(r[2]) : null
// }
export const queryString = (search, name) => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = search.substr(1).match(reg)
    return r ? unescape(r[2]) : null
}

export function getCookie(name) {
    if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(name + '=')
        if (start !== -1) {
            start = start + name.length + 1
            let end = document.cookie.indexOf(';', start)
            if (end === -1) {
                end = document.cookie.length
            }
            return unescape(document.cookie.substring(start, end))
        }
    }
    return ''
}

/**
 * 设置 cookie
 * @key : cookie 名
 * @value : cookie 值
 * @options : 可选配置参数
 *      options = {
 *          expires : 7|new Date(), // 失效时间
 *          path : "/", // 路径
 *          domain : "", // 域名
 *          secure : true // 安全连接
 *      }
 */
export const cookie = (key, value, options) => {
    /* read 读取 */
    // 如果没有传递 value ，则表示根据 key 读取 cookie 值
    if (typeof value === 'undefined') { // 读取
        // 获取当前域下所有的 cookie，保存到 cookies 数组中
        let cookies = document.cookie.split('; ')
        // 遍历 cookies 数组中的每个元素
        for (let i = 0, len = cookies.length; i < len; i++) {
            // cookies[i] : 当前遍历到的元素，代表的是 'key=value' 意思的字符串,
            // 将字符串以 = 号分割返回的数组中第一个元素表示 key，
            // 第二个元素表示 value
            let cookie = cookies[i].split('=')
            // 判断是否是要查找的 key，对查找的 key 、value 都要做解码操作
            if (decodeURIComponent(cookie[0]) === key) {
                return decodeURIComponent(cookie[1])
            }
        }
        // 没有查找到指定的 key 对应的 value 值，则返回 null
        return null
    }

    /* 存入 设置 */
    // 设置 options 默认为空对象
    options = options || {}
    // key = value，对象 key，value 编码
    let cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value)
    // 失效时间
    if ((typeof options.expires) !== 'undefined') { // 有配置失效时间
        if (typeof options.expires === 'number') { // 失效时间为数字
            const days = options.expires
            const t = options.expires = new Date()
            t.setDate(t.getDate() + days)
        }
        cookie += ';expires=' + options.expires.toUTCString()
    }
    // 路径
    if (typeof options.path !== 'undefined') {
        cookie += ';path=' + options.path
    }
    // 域名
    if (typeof options.domain !== 'undefined') {
        cookie += ';domain=' + options.domain
    }
    // 安全连接
    if (options.secure) {
        cookie += ';secure'
    }

    // 保存
    document.cookie = cookie
}

// 从所有的 cookie 中删除指定的 cookie
export const removeCookie = (key, options) => {
    options = options || {}
    options.expires = -1 // 将失效时间设置为 1 天前
    cookie(key, '', options)
}

/**
 * 使用 localstorage 存取数据
 */
export const storeKey = '_xin_yi_dai_'

export const loadStorage = () => JSON.parse(window.localStorage.getItem(storeKey) || '{}')

export const saveStorage = data => {
    window.localStorage.setItem(storeKey, JSON.stringify(data))
}

export const setStore = (key, value, exprited_time) => {
    let data = loadStorage()
    data[key] = value
    if (exprited_time) {
        data[key + '_exprited_at'] = (new Date()).getTime() + exprited_time * 1000
    }
    saveStorage(data)
}

export const loadStore = key => {
    let data = loadStorage()
    return data[key] || ''
}

export const scrollToTop = () => {
    let scrollToTop = window.setInterval(function () {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 2);
}


/**
 * 全局显示消息弹框
 * @param msg 消息内容
 * @param time 显示时长（默认1.5秒后关闭）
 * @param type 弹框类型['info','error','success','warning']（默认为info）
 * @param onClose 关闭时的回调函数
 */
export const showMsg = (msg, time = 1.5, type = 'info', onClose = null) => {
    message[type](msg, time, onClose);
};


// DebugLog
export const DebugLog = (tip = '', obj = '') => {
    if (window.ENV = 'development') {

    }
}

// AES 加密
export const aesEncrypt = (data) => {
    let encrypted = '';
    let key = CryptoJS.enc.Utf8.parse('PVrW9sRqsT37cJhnqxgcHWvbCrsjcRRT');
    let iv = CryptoJS.enc.Utf8.parse('PVrW9sRqsT37cJhn');
    if (typeof (data) == 'string') {
        encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    } else if (typeof (data) == 'object') {
        data = JSON.stringify(data);
        encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    }
    return encrypted.toString();
}

// AES 解密
export const aesDecrypt = (data) => {
    let decrypted = '';
    let key = CryptoJS.enc.Utf8.parse('PVrW9sRqsT37cJhnqxgcHWvbCrsjcRRT');
    let iv = CryptoJS.enc.Utf8.parse('PVrW9sRqsT37cJhn');
    decrypted = CryptoJS.AES.decrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

let uid = Date.now()

export function nextUid() {
    return (uid++).toString(36)
}

export const genUUID = () => {
    const s = [];
    const hexDigits = "0123456789abcdef";
    for (let i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23];

    const uuid = s.join("");
    return uuid;
}

// 获取阿里云OSS上的图片地址
export const getFileUrl = ({
    file,
    duration = 3600,
    baseURL,
    bucketName = 'pht-content',
    accessKeyId = 'LTAIFOd1MjVZAXeF',
    accessKeySecret = 'tU9Yr3IW2ZN0HNmxCLe24APGUbqasV',
    method = 'GET',
    contentMd5 = '',
    contentType = ''
}) => {
    baseURL = baseURL || 'https://pht-content.oss-cn-beijing.aliyuncs.com'
    const CanonicalizedOSSHeaders = ''
    const CanonicalizedResource = `/${bucketName}/${file}?x-oss-process=image/resize,w_500`
    const currentTime = Math.floor(Date.now() / 1000) // 单位为 s
    if (typeof duration !== 'number') {
        duration = parseInt(duration)
    }
    const expire = currentTime + duration

    let transStr = []
    // 一次按顺序添加
    transStr.push(method) // 请求方法
    transStr.push(contentMd5) // Content-MD5 默认为空
    transStr.push(contentType) // Content-Type 默认为空
    transStr.push(expire) // 此次操作的时间
    // if (CanonicalizedOSSHeaders.length > 0) {
    //   transStr.push(CanonicalizedOSSHeaders)
    // }
    transStr.push(CanonicalizedResource)
    transStr = transStr.join('\n')

    const sha1 = new Hashes.SHA1()
    const jsHashesResult = encodeURIComponent(sha1.b64_hmac(accessKeySecret, transStr))

    return `${baseURL}/${file}?x-oss-process=image/resize,w_500&OSSAccessKeyId=${accessKeyId}&Expires=${expire}&Signature=${jsHashesResult}`
}
/**
 * 时间格式化
 * @param date  时间  Date对象
 * @param fmt  格式 ‘yyyy-MM-dd HH:mm:ss’
 * @returns {*} 格式化后的时间  "2018-09-06 10:15:59"
 * @constructor
 */
export const Dateformat = (date, fmt) => {
    var o = {
        "M+": date.getMonth() + 1,                 //月份
        "d+": date.getDate(),                    //日
        "h+": date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, //小时
        "H+": date.getHours(),                   //小时
        "m+": date.getMinutes(),                 //分
        "s+": date.getSeconds(),                 //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}


//1门户，2基金，3投融资，4股债联动，5热推政策，6金融知识库
export const GetBanner = (bannerType) => {
    return new Promise((resolve, reject) => {
        GET(API.getBanner, { bannerType, isPage: 1 }).then(res => {
            if (res.code == 200) {
                resolve(res)
            } else {
                reject()
            }
        })
    })
}

//1：新闻管理；2：通知公告；3：基金资讯；4：股债联动资讯；5：投融资咨询
export const GetNews = (type) => {
    return new Promise((resolve, reject) => {
        GET(API.getNews, { type, isPage: 1 }).then(res => {
            if (res.code == 200) {
                resolve(res)
            } else {
                reject()
            }
        })
    })
}

export const getBase64Data = (base64) => {
    let data = base64
    if (base64 && base64.indexOf('base64,') !== -1) {
        data = base64.split('base64,')[1]
    }
    return data
}


export const getCompInfo = (props = {}) => {
    let { user = {} } = props
    let { userInfo = {} } = user
    let { comp = {} } = userInfo
    return comp
}
export const getUserId = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.userId
    } else {
        return ''
    }
}
export const getUserName = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.userName
    } else {
        return ''
    }
}
export const getUserTel = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.tel
    } else {
        return ''
    }
}
export const getUserType = (props = {}) => {
    let { user = {} } = props
    if (user?.isLogin) {
        let { userInfo = {} } = user
        return userInfo?.comp?.compType
    } else {
        return ''
    }
}
export const getCompId = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.companyId
    } else {
        return ''
    }
}
export const getCompUUId = (props = {}) => {
    let { user = {} } = props
    let { userInfo = {} } = user
    let { comp = {} } = userInfo
    return comp.uuid
}
export const isAdmin = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.isAdmin === '1' || userInfo.isAdmin === 1
    } else {
        return null
    }
}
export const getCompType = (props = {}) => {
    let { user = {} } = props
    if (user.isLogin) {
        let { userInfo = {} } = user
        return userInfo.companyType || undefined
    } else {
        return undefined
    }
}
export const getCompName = (props = {}) => {
    let { user = {} } = props
    if (user && user.isLogin && user.userInfo && user.userInfo.comp && user.userInfo.comp.compFullName) {
        return user.userInfo.comp.compFullName
    } else {
        return undefined
    }
}
export const getCompCode = (props = {}) => {
    let { user = {} } = props
    if (user && user.isLogin && user.userInfo && user.userInfo.comp && user.userInfo.comp.creditCode) {
        return user.userInfo.comp.creditCode
    } else {
        return undefined
    }
}
/**
 * 获取用户角色列表key
 * @param {*} props 
 */
export const getUserRoleKeys = (props = {}) => {
    let { user = {} } = props
    if (user && user.isLogin && user.userInfo) {
        return user.userInfo.roleKeys
    } else {
        return undefined
    }
}
/**
 * 获取企业高管实名状态  0否   1是
 * @param {*} props 
 * @returns 
 */
export const getCompExecutivesCertificationStatus = (props = {}) => {
    let { user = {} } = props
    if (user && user.isLogin && user.userInfo) {
        return user.userInfo.compExecutivesCertificationStatus
    } else {
        return undefined
    }
}
export const isLogin = (props = {}) => {
    let { user = {} } = props
    return user.isLogin
}

export const toLogin = (props = {}) => {
    props.history.push('/login')
}

export const isInvestor = (props = {}) => {
    let { user = {} } = props
    let { userInfo = {} } = user
    if ((userInfo.comp && userInfo.comp.isInvestor === '1') || userInfo.isInvestor === '1') {
        return true
    } else {
        return false
    }
}

/**
 * 获取html字符串中的文字内容（去掉标签）
 * @param htmlcontent
 * @return
 */
export const filterText = (str) => {
    if (!str) return str
    str = str.replace(/<\/?[^>]*>/g, '') // 去除HTML tag
    str = str.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
    str = str.replace(/ /ig, '') // 去掉
    str = str.replace(/&nbsp;/g, '') // 去掉
    return str
}

export const PUBLIC_KEY = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\nnzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ=='

export const getDict = (dictType) => {
    return new Promise((resolve, reject) => {
        GET(API.getDictNew + dictType).then(res => {
            if (res.code == 200) {
                if (res.data && isArray(res.data)) {
                    // let dict = {}
                    // res.data.forEach((item,index)=>{
                    //     let label = item.dictLabel
                    //     dict[label] = item.dictValue
                    // })
                    // 
                    resolve(res.data || [])
                }

            } else {
                reject()
            }
        })
    })
}
export const compareTime = (time1, time2) => {
    if (time1 && time2 && new Date(time1).getTime() > new Date(time2).getTime()) {
        return true
    } else {
        return false
    }
}
export const getUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


export const getDictByValue = (dictionaryValue) => {
    return new Promise((resolve, reject) => {
        GET(API.getDictLabel, { dictionaryValue }).then(res => {
            if (res.code == 200) {
                let info = res.data
                if (info && info.child && isArray(info.child)) {
                    resolve(info.child || [])
                }
            } else {
                reject()
            }
        })
    })
}

export const getBannerUrl = (url) => {
    if (url.indexOf('http') == -1) {
        return ''
    }
    return url
}

export const getLabelByValue = (value, list = [], labelField = 'dictionaryLabel', valueField = 'dictionaryValue') => {
    for (let i = 0; i < list.length; i++) {
        let item = list[i]
        if (item[valueField] == value) {
            return item[labelField]
        }
    }
}

export const getYestoday = () => {
    //昨天的时间 
    let today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
}
export const extName = (filename) => {
    if (!filename || typeof filename != 'string') {
        return null
    };
    let a = filename.split('').reverse().join('');
    let b = a.substring(0, a.search(/\./)).split('').reverse().join('');
    return b
};
export const fileName = (filenameStr) => {
    if (!filenameStr || typeof filenameStr != 'string') {
        return null
    };
    let arr = filenameStr.split('/');
    return arr && arr.length > 0 ? arr[arr.length - 1] : filenameStr
};

// 地区：根据code获取label
export const getLabelByCode = (key, treeData) => {

    let arr = []; // 在递归时操作的数组
    let returnArr = []; // 存放结果的数组
    let depth = 0; // 定义全局层级
    // 定义递归函数
    function childrenEach(childrenData, depthN) {

        for (var j = 0; j < childrenData.length; j++) {

            depth = depthN; // 将执行的层级赋值 到 全局层级

            // arr[depthN] = (childrenData[j].value);
            arr[depthN] = (childrenData[j].label);

            if (childrenData[j].value == key) {
                // returnArr = arr; // 原写法不行, 因 此赋值存在指针关系
                returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
                break

            } else {

                if (childrenData[j].children) {

                    depth++;
                    childrenEach(childrenData[j].children, depth);

                }
            }

        }
        return returnArr;
    }
    return childrenEach(treeData, depth);
}


export const isMobile = () => {
    const userAgent = window.navigator.userAgent;
    return userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*|Wechat|MiniProgram|webview)/i);
}