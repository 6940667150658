import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal,Button} from 'antd';
class DialogBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            alertStatus: false, //是否显示提示框

            title:'提示', //标题
            content:'', //提示内容
            cancelText:'取消',
            confirmText:'确认',
            popup: false,//是否弹窗模式
            maskClosable: true,//点击蒙层是否允许关闭
            animationType: 'fade', // 'slide-down / up' / 'fade' / 'slide'
            isShowCancel:false, //是否显示确认按钮
            isShowConfirm:true, //是否显示确认按钮
            closable: true,//是否显示关闭按钮
            cancelCallbackFn:function(){}, //取消 回调函数
            confirmCallbackFn:function (){}//确认 回调函数
        }
    }

    //打开提示框
    show(options){
        options = options || {};
        options.alertStatus = true;
        this.setState({
            ...options
        })
    }
    //取消
    cancel(){
        console.log('cancel___')
        this.state.cancelCallbackFn();
        this.close()
    }
    //确认
    confirm () {
        this.state.confirmCallbackFn();
        this.close()
    }
    close (){
        this.setState({
            alertStatus:false
        })
    }
    btnClick = () => {
        if(this.state.btnClick){
            this.state.btnClick()
        }
        this.close()
    }
    render(){
        let opts = this.state;
        return (
            <Modal
                visible={opts.alertStatus}
                footer={null}
                onCancel={()=>this.cancel()}
            >
                <p>{opts.content}</p>
                <div className="ccc" style={{ padding: '1.5rem 0' }}>
                    <div style={{ color: '#333', fontSize: 16, marginBottom: '1rem' }}>{opts.label || ''}</div>
                    {opts.btnText && <Button type="primary" style={{ marginTop: '1rem' }} onClick={this.btnClick}>{opts.btnText || ''}</Button>}
                </div>

            </Modal>
        )
    }
}

let div = document.createElement('div');
document.body.appendChild(div);
let AuthModal = ReactDOM.render(<DialogBox /> ,div);
export default AuthModal;
