
import * as types from '../utils/constants'
import { loadStore, setStore, storeKey } from '@/utils/util'
const sToken = loadStore('token')
const mLabelList = loadStore('labelList')
const mCompList = loadStore('compList')
const initialState = {
    userInfo: loadStore('userInfo') || {},
    isLogin: !!sToken,
    token: sToken || '',
    labelList: mLabelList,
    compList:mCompList,
    unReadCount: 0
};

export default function user(state = initialState, action) {
    state = Object.assign({}, state, {
        fetching: false,
    });
    switch (action.type) {
        case types.LOGIN:
            setStore('token', action.data)
            return Object.assign({}, state, {
                isLogin: true,
                token: action.data
            });
        case types.GET_USER_INFO:
            setStore('userInfo', action.data)
            return Object.assign({}, state, {
                userInfo: action.data
            });
        case types.COMP_LIST:
            setStore('compList', action.data)
            return Object.assign({}, state, {
                compList: action.data
            });
        case types.LOGOUT:
            window.localStorage.removeItem(storeKey)
            return Object.assign({}, state, {
                userInfo: {},
                isLogin: false,
                token: ''
            });
        case types.LABEL_LIST:
            setStore('labelList', action.data)
            return Object.assign({}, state, {
                labelList: action.data
            });
        case types.UN_READ_COUNT:
            return Object.assign({}, state, {
                unReadCount: action.data
            });
        default:
            return state;
    }
}
