import AuthModal from '@/logic/AuthModal'
import { isMobile} from '@/utils/util'

export let logicCheck = (props, level = 2) => {

    const { user } = props
    let auditStatus = user.userInfo.auditStatus

    if (!user.isLogin) {
        AuthModal.show({
            label: '您还未登录，请登录后继续操作',
            btnText: '去登录',
            btnClick: () => {
                props.history.push(isMobile()?'/m/login':'/login')
            }
        })
        return true
    }

    if (level == 1) return

    if (!auditStatus || auditStatus == null || auditStatus == 0) {
        AuthModal.show({
            label: '您还没有实名认证',
            btnText: '去实名',
            btnClick: () => {
                props.history.push('/personalCenter/compAuth')
            }
        })
        return true
    }

    if (parseInt(auditStatus) == 1) {
        AuthModal.show({
            label: '您的实名认证正在审核中',
            btnText: '知道了',
            btnClick: () => { }
        })
        return true
    }

    if (parseInt(auditStatus) == -1) {
        AuthModal.show({
            label: '您的实名认证被驳回',
            btnText: '知道了',
            btnClick: () => { }
        })
        return true
    }



    


    return false

}