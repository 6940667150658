import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import AsyncComponent from './AsyncComponent'
import PersonalCenter from '@/pages/PersonalCenter/PersonalCenter'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import {isMobile} from "@/utils/util";
moment.locale('zh-cn')

const createHistory = require('history').createHashHistory
const history = createHistory()
const supportsHistory = 'pushState' in window.history

const routes = [
  {
    path: '/home', // 首页
    component: () => import('@/pages/HomePage/HomePage'),
  },
  {
    path: '/login', //登录
    component: () => import('@/pages/Login/Login'),
  },
  {
    path: '/register',
    component: () => import('@/pages/Register/RegisterPage'),
  },
  {
    path: '/realNameAuth', //实名认证
    component: () => import('@/pages/Register/RealNameAuth'),
  },
  {
    path: '/realNameAuth2/:userId/:compId', //实名认证
    component: () => import('@/pages/Register/RealNameAuth2'),
  },
  {
    path: '/forgetPwd', //忘记密码
    component: () => import('@/pages/Login/ForgetPwd'),
  },
  {
    path: '/personalCenter', //个人中心
    component: () => import('@/pages/PersonalCenter/PersonalCenter'),
  },
  {
    path: '/news/:id?', // 新闻公告
    component: () => import('@/pages/News/News'),
  },
  {
    path: '/newsMore/:id?', // 新闻更多
    component: () => import('@/pages/News/NewsMore'),
  },
  {
    path: '/newsDetail/:newsId', // 新闻详情
    component: () => import('@/pages/News/NewsDetail'),
  },
  {
    path: '/serviceSystem', // 服务体系
    component: () => import('@/pages/ServiceSystem/ServiceSystem'),
  },
  {
    path: '/integrityCodeIntroduce', // 诚信码介绍
    component: () => import('@/pages/IntegrityCodeIntroduce/IntegrityCodeIntroduce'),
  },
  {
    path: '/stockBondLink', // 股债联动
    component: () => import('@/pages/StockBondLink/StockBondLink'),
  },
  {
    path: '/policyGuidance/:index?', // 精准惠企
    component: () => import('@/pages/PolicyGuidance/PolicyGuidance'),
  },
  {
    path: '/policyDetail/:policyId', // 政策详情
    component: () => import('@/pages/PolicyGuidance/PolicyDetail'),
  },
  {
    path: '/qualificationDetail/:qualificationsId', // 资质详情
    component: () => import('@/pages/PolicyGuidance/QualificationDetail'),
  },
  {
    path: '/policyApply/:policyId/:applyId?/:disabled?', // 政策申请
    component: () => import('@/pages/PolicyGuidance/PolicyApply'),
  },
  {
    path: '/qualificationDetailSelf/:qualificationsId/:applyId?', // 资质详情 企业自填
    component: () => import('@/pages/PolicyGuidance/QualificationDetailSelf'),
  },
  {
    path: '/qualificationDetailSelfResult', // 资质详情 申请成功页
    component: () =>
      import('@/pages/PolicyGuidance/QualificationDetailSelfResult'),
  },
  {
    path: '/financialService', //金融服务-金融产品
    component: () => import('@/pages/FinancialService/FinancialService'),
  },
  {
    path: '/productDetail/:id', //金融产品详情
    component: () => import('@/pages/FinancialService/ProductDetail'),
  },
  {
    path: '/applyLoan/:id', //申请贷款
    component: () => import('@/pages/FinancialService/ApplyLoan'),
  },
  {
    path: '/financialOrg', //金融服务-金融机构
    component: () => import('@/pages/FinancialService/FinancialOrg'),
  },
  {
    path: '/financialOrgDetail/:id', //金融服务-金融机构详情
    component: () => import('@/pages/FinancialService/FinancialOrgDetail'),
  },
  {
    path: '/financeDemand', //金融服务-贷款需求表单
    component: () => import('@/pages/FinancialService/FinanceDemandForm'),
  },
  {
    path: '/smartMatch', //金融服务-贷款需求表单
    component: () => import('@/pages/FinancialService/SmartMatch'),
  },
  {
    path: '/aboutUs', //关于我们
    component: () => import('@/pages/AboutUs/AboutUs'),
  },
  {
    path: '/platformIntroduction', //平台介绍
    component: () =>
      import('@/pages/PlatformIntroduction/PlatformIntroduction'),
  },

  {
    path: '/aboutUsContact', //关于我们-联系我们
    component: () => import('@/pages/AboutUs/AboutUsContact'),
  },
  {
    path: '/aboutUsHelp', //关于我们-帮助中心
    component: () => import('@/pages/AboutUs/AboutUsHelp'),
  },
  {
    path: '/aboutUsFeedback', //关于我们-意见反馈
    component: () => import('@/pages/AboutUs/AboutUsFeedback'),
  },
  {
    path: '/aboutUsMap', //关于我们-网站地图
    component: () => import('@/pages/AboutUs/AboutUsMap'),
  },
  {
    path: '/aboutUsLinks', //关于我们-相关链接
    component: () => import('@/pages/AboutUs/AboutUsLinks'),
  },
  {
    path: '/aboutUsLaw', //关于我们-法律声明
    component: () => import('@/pages/AboutUs/AboutUsLaw'),
  },
  {
    path: '/messageList', //消息列表
    component: () => import('@/pages/PersonalCenter/MessageList'),
  },
  {
    path: '/companyIndexH5/:id', //企业码H5首页
    component: () => import('@/pages/H5/Company/CompanyIndexH5'),
  },
  {
    path: '/companyIndexShare/:id/:compType', //企业码H5分享
    component: () => import('@/pages/H5/Company/CompanyIndexShare'),
  },
  {
    path: '/companyIntroduction', //企业基本信息
    component: () => import('@/pages/H5/Company/CompanyIntroduction'),
  },
  {
    path: '/companyPeople', //企业人员信息
    component: () => import('@/pages/H5/Company/CompanyPeople'),
  },
  {
    path: '/companyCredit', //企业信用信息
    component: () => import('@/pages/H5/Company/CompanyCredit'),
  },
  {
    path: '/companyAssets', //企业资产
    component: () => import('@/pages/H5/Company/CompanyAssets'),
  },
  {
    path: '/companyManagement', //经营信息
    component: () => import('@/pages/H5/Company/CompanyManagement'),
  },
  {
    path: '/govmCodeDesc', //政府码H5首页
    component: () => import('@/pages/H5/Govm/GovmCodeDesc'),
  },
  {
    path: '/govmIndexH5', //政府码H5首页
    component: () => import('@/pages/H5/Govm/GovmIndex'),
  },
  {
    path: '/govmIntroduction', //政府基本信息
    component: () => import('@/pages/H5/Govm/GovmIntroduction'),
  },
  {
    path: '/govmApply', //政府办公申请
    component: () => import('@/pages/H5/Govm/GovmApply'),
  },
  {
    path: '/govmPolicy', //政府企业政策集成
    component: () => import('@/pages/H5/Govm/GovmPolicy'),
  },
  {
    path: '/govmRegisterAppointment', //政府注册预约
    component: () => import('@/pages/H5/Govm/GovmRegisterAppointment'),
  },
  {
    path: '/orgIndexH5', //机构H5首页
    component: () => import('@/pages/H5/Org/OrgIndex'),
  },
  {
    path: '/orgIntroduction', //机构基本信息
    component: () => import('@/pages/H5/Org/OrgIntroduction'),
  },
  {
    path: '/orgFinanceProduct', //机构金融产品
    component: () => import('@/pages/H5/Org/OrgFinanceProduct'),
  },
  {
    path: '/orgPresident', //机构银行行长
    component: () => import('@/pages/H5/Org/OrgPresident'),
  },
  {
    path: '/orgCreditManager', //机构信贷经理
    component: () => import('@/pages/H5/Org/OrgCreditManager'),
  },
  {
    path: '/orgCreditManagerDetail', //机构信贷经理详情
    component: () => import('@/pages/H5/Org/OrgCreditManagerDetail'),
  },
  {
    path: '/orgFinanceProductDetail', //机构金融产品详情
    component: () => import('@/pages/H5/Org/OrgFinanceProductDetail'),
  },
  {
    path: '/compDetail/:id/:tab?', //融资企业信息详情
    component: () => import('@/pages/Info/CompDetail/CompDetail'),
  },
  {
    path: '/renewalLoan', //无还本续贷
    component: () => import('@/pages/RenewalLoan/RenewalLoan'),
  },
  {
    path: '/renewalLoanForm', //无还本续贷表单
    component: () => import('@/pages/RenewalLoan/RenewalLoanForm'),
  },
  {
    path: '/m/login', //H5登录
    component: () => import('@/pages/H5/Login/LoginH5'),
  },
  {
    path: '/m/register', //H5注册
    component: () => import('@/pages/H5/Login/RegisterH5'),
  },
  {
    path: '/m/forgetPwd', //H5忘记密码
    component: () => import('@/pages/H5/Login/ForgetPwdH5'),
  },
  {
    path: '/m/compAuth', //H5企业认证-法人认证
    component: () => import('@/pages/H5/Login/CompAuthH5'),
  },
  {
    path: '/m/compExecutivesAuth', //H5企业高管认证
    component: () => import('@/pages/H5/Login/CompExecutivesAuthH5'),
  },
  {
    path: '/m/result', //H5结果页
    component: () => import('@/pages/H5/ResultH5'),
  },
  {
    path: '/m/introduceGovmCode', //政府机构服务码
    component: () => import('@/pages/H5/Introduce/IntroduceGovmCode'),
  },
  {
    path: '/m/index', //H5首页
    component: () => import('@/pages/H5/Index/Index'),
  },
  {
    path: '/m/product', //H5产品列表页
    component: () => import('@/pages/H5/Index/IndexProduct'),
  },
  {
    path: '/m/loanservice', //H5贷款服务页
    component: () => import('@/pages/H5/Index/IndexLoanservice'),
  },
  {
    path: '/m/loaninfo/:id', //H5贷款服务页
    component: () => import('@/pages/H5/Loan/Loaninfo'),
  },
  {
    path: '/m/riskwarning', //H5风险预警
    component: () => import('@/pages/H5/Index/IndexRiskwarning'),
  },
  {
    path: '/m/introduce', //H5介绍
    component: () => import('@/pages/H5/Index/IndexIntroduce'),
  },
  {
    path: '/m/qr/introduce', //H5诚信码介绍
    component: () => import('@/pages/H5/QRIntroduce/QRIntroduce'),
  },
  {
    path: '/m/me', //H5我的
    component: () => import('@/pages/H5/Index/IndexMe'),
  },
  {
    path: '/m/policy', //H5政策列表页
    component: () => import('@/pages/H5/Policy/Policy'),
  },
  {
    path: '/m/policyDetail/:id', //H5政策详情页
    component: () => import('@/pages/H5/Policy/PolicyDetail'),
  },
  {
    path: '/m/applyPolicy', //H5政策-我申请的政策
    component: () => import('@/pages/H5/Policy/ApplyPolicy'),
  },
  {
    path: '/m/obtainPolicy', //H5政策-政策获取
    component: () => import('@/pages/H5/Policy/ObtainPolicy'),
  },
  {
    path: '/m/productDetail/:id', //H5产品详情
    component: () => import('@/pages/H5/Product/ProductDetail'),
  },
  {
    path: '/m/productApply/:id', //H5产品申请
    component: () => import('@/pages/H5/Product/ProductApply'),
  },
  {
    path: '/m/financeDemandApply', //H5贷款申请
    component: () => import('@/pages/H5/FinanceDemand/FinanceDemandApply'),
  },
  {
    path: '/m/appliedFinanceDemand', //H5贷款申请列表
    component: () => import('@/pages/H5/FinanceDemand/AppliedFinanceDemand'),
  },
  {
    path: '/m/appliedFinanceDemandDetail/:id', //H5贷款申请详情
    component: () => import('@/pages/H5/FinanceDemand/AppliedFinanceDemandDetail'),
  },
  {
    path: '/m/appliedProduct', //H5产品申请
    component: () => import('@/pages/H5/Product/AppliedProduct'),
  },
  {
    path: '/m/appliedProductDetail/:id', //H5产品申请
    component: () => import('@/pages/H5/Product/AppliedProductDetail'),
  },
  {
    path: '/m/tobeauthProduct', //
    component: () => import('@/pages/H5/Product/TobeauthProduct'),//待鉴权列表
  },
  {
    path: '/m/tobeauthProductDetail/:id', //
    component: () => import('@/pages/H5/Product/TobeauthProductDetail'),//待鉴权产品
  },
  {
    path: '/m/tobeauthDemand', //
    component: () => import('@/pages/H5/FinanceDemand/TobeauthDemand')//
  },
  {
    path: '/m/tobeauthDemandDetail/:id', //
    component: () => import('@/pages/H5/FinanceDemand/TobeauthDemandDetail')//
  },
  {
    path: '/m/news/list/:type', //H5新闻列表
    component: () => import('@/pages/H5/News/NewsList'),
  },
  {
    path: '/m/news/detail/:newsId', //H5新闻详情
    component: () => import('@/pages/H5/News/NewsDetail'),
  },
  {
    path: '/m/personalCenter/editpwd', //H5修改密码
    component: () => import('@/pages/H5/PersonalCenter/EditPwd'),
  },
  {
    path: '/m/personalCenter/setting', //H5设置
    component: () => import('@/pages/H5/PersonalCenter/SettingMe'),
  },
  {
    path: '/m/message/list', //H5消息通知
    component: () => import('@/pages/H5/PersonalCenter/NoticeList'),
  },
  {
    path: '/m/orgDetail/:id', //H5金融机构详情
    component: () => import('@/pages/H5/Org/OrgDetail'),
  },
  {
    path: '/m/creditManagerDetail/:id', //H5信贷经理详情
    component: () => import('@/pages/H5/Org/CreditManagerDetail'),
  },
]

const personalRoutes = [
  {
    path: '/personalCenter', //个人信息
    component: () => import('@/pages/PersonalCenter/PersonalInfo'),
  },
  {
    path: '/personalCenter/userInfo', //个人信息
    component: () => import('@/pages/PersonalCenter/PersonalInfo'),
  },
  {
    path: '/personalCenter/organizationCenter', //机构中心
    component: () =>
      import('@/pages/PersonalCenter/OrganizationCenter/OrganizationCenter'),
  },
  {
    path: '/personalCenter/compAuth', //企业认证
    component: () => import('@/pages/PersonalCenter/CompAuth'),
  },
  {
    path: '/personalCenter/companyRate', //企业评分
    component: () => import('@/pages/PersonalCenter/CompanyRate/CompanyRate'),
  },
  {
    path: '/personalCenter/compLabel', //企业标签
    component: () => import('@/pages/PersonalCenter/CompLabel'),
  },
  {
    path: '/personalCenter/myCollectProduct', //我收藏的产品
    component: () =>
      import('@/pages/PersonalCenter/MyCollect/MyCollectProduct'),
  },
  {
    path: '/personalCenter/myCollectPolicy', //我收藏的政策
    component: () => import('@/pages/PersonalCenter/MyCollect/MyCollectPolicy'),
  },
  {
    path: '/personalCenter/userManage', //系统管理-用户管理
    component: () => import('@/pages/PersonalCenter/SystemManage/UserManage'),
  },
  {
    path: '/personalCenter/personManage', //系统管理-人员管理
    component: () => import('@/pages/PersonalCenter/SystemManage/PersonManage'),
  },
  {
    path: '/personalCenter/loanServiceEvaluation', //系统管理-贷款服务评价列表
    component: () =>
      import('@/pages/PersonalCenter/SystemManage/LoanServiceEvaluation'),
  },
  {
    path: '/personalCenter/myApplicationPolicy', //政策引领-我申请的政策
    component: () =>
      import('@/pages/PersonalCenter/PolicyGuidance/MyApplicationPolicy'),
  },
  {
    path: '/personalCenter/accessPolicy', //政策引领-政策获取
    component: () =>
      import('@/pages/PersonalCenter/PolicyGuidance/AccessPolicy'),
  },
  {
    path: '/personalCenter/myLoanApplyList', //我的贷款-贷款产品申请列表
    component: () => import('@/pages/PersonalCenter/MyLoan/MyLoanApplyList'),
  },
  {
    path: '/personalCenter/myPublishList', //我的贷款-需求发布列表
    component: () => import('@/pages/PersonalCenter/MyLoan/MyPublishList'),
  },
  {
    path: '/personalCenter/myPublishDetail', //我的贷款-需求详情
    component: () => import('@/pages/PersonalCenter/MyLoan/MyPublishDetail'),
  },
  {
    path: '/personalCenter/myLoanComment', //我的贷款-贷款服务评价
    component: () => import('@/pages/PersonalCenter/MyLoan/MyLoanComment'),
  },
  {
    path: '/personalCenter/myQualification', //资质引领引领
    component: () =>
      import('@/pages/PersonalCenter/QualificationGuidance/MyQualification'),
  },
  {
    path: '/personalCenter/myQualificationDetail/:applyId', //资质引领引领 详情
    component: () =>
      import(
        '@/pages/PersonalCenter/QualificationGuidance/MyQualificationDetail'
      ),
  },
  {
    path: '/personalCenter/msgCenter', //消息中心
    component: () => import('@/pages/PersonalCenter/MsgCenter/MsgCenter'),
  },
  {
    path: '/personalCenter/myLoanList', //贷款需求管理-我的贷款需求
    component: () =>
      import('@/pages/PersonalCenter/LoanDemandManage/MyLoanList'),
  },
  {
    path: '/personalCenter/loanDemandGrab', //贷款需求管理-贷款需求抢单
    component: () =>
      import('@/pages/PersonalCenter/LoanDemandManage/LoanDemandGrab'),
  },
  {
    path: '/personalCenter/myAcceptLoanDemand', //贷款需求管理-我受理的贷款需求
    component: () =>
      import('@/pages/PersonalCenter/LoanDemandManage/MyAcceptLoanDemand'),
  },
  {
    path: '/personalCenter/myApplyFinancingEnterprise', //贷款需求管理-我申请的融资企业
    component: () =>
      import(
        '@/pages/PersonalCenter/LoanDemandManage/MyApplyFinancingEnterprise'
      ),
  },
  {
    path: '/personalCenter/applyLookMe', //贷款需求管理-申请查看我的金融机构
    component: () =>
      import('@/pages/PersonalCenter/LoanDemandManage/ApplyLookMe'),
  },
  {
    path: '/personalCenter/renewCreditApplyList', //无还本续贷申请列表
    component: () =>
      import('@/pages/PersonalCenter/MyRenewalLoan/MyRenewalLoanApplyList'),
  },
  {
    path: '/personalCenter/renewalLoanListQuotedPrice', //无还本续贷报价
    component: () =>
      import('@/pages/PersonalCenter/MyRenewalLoan/RenewalLoanListQuotedPrice'),
  },
  {
    path: '/personalCenter/renewalLoanListConfirm', //无还本续贷确认
    component: () =>
      import('@/pages/PersonalCenter/MyRenewalLoan/RenewalLoanListConfirm'),
  },
  {
    path: '/personalCenter/renewalLoanListDischarged', //无还本续贷放贷
    component: () =>
      import('@/pages/PersonalCenter/MyRenewalLoan/RenewalLoanListDischarged'),
  },
  {
    path: '/personalCenter/financialProductMaintain', //金融产品管理-金融产品维护
    component: () =>
      import(
        '@/pages/PersonalCenter/FinancialProductManage/FinancialProductMaintain'
      ),
  },
  {
    path: '/personalCenter/myAcceptProduct', //金融产品管理-我受理的产品
    component: () =>
      import('@/pages/PersonalCenter/FinancialProductManage/MyAcceptProduct'),
  },
  {
    path: '/personalCenter/myApplyProduct', //金融产品管理-我申请的产品
    component: () =>
      import('@/pages/PersonalCenter/FinancialProductManage/MyApplyProduct'),
  },
  {
    path: '/personalCenter/productApplyGrab', //金融产品管理-产品申请抢单
    component: () =>
      import('@/pages/PersonalCenter/FinancialProductManage/ProductApplyGrab'),
  },
  {
    path: '/personalCenter/riskWarning', //风险预警
    component: () =>
      import('@/pages/PersonalCenter/RiskManage/RiskWarning'),
  },
  {
    path: '/personalCenter/editPwd', //修改密码
    component: () => import('@/pages/PersonalCenter/EditPwd'),
  },

  {
    path: '/personalCenter/compInfo/baseInfo', //融资企业信息完善-基本信息
    component: () => import('@/pages/PersonalCenter/CompInfo/BaseInfo'),
  },
  {
    path: '/personalCenter/compInfo/assetInfo', //融资企业信息完善-资产信息
    component: () => import('@/pages/PersonalCenter/CompInfo/AssetInfo'),
  },
  {
    path: '/personalCenter/compInfo/financeInfo', //融资企业信息完善-财务信息
    component: () => import('@/pages/PersonalCenter/CompInfo/FinanceInfo'),
  },
  {
    path: '/personalCenter/compInfo/creditInfo', //融资企业信息完善-信用信息
    component: () => import('@/pages/PersonalCenter/CompInfo/CreditInfo'),
  },
  {
    path: '/personalCenter/compInfo/talentPerson', //融资企业信息完善-人才信息
    component: () => import('@/pages/PersonalCenter/CompInfo/TalentPerson'),
  },
  {
    path: '/personalCenter/compInfo/innovationOutput', //融资企业信息完善-创新产出
    component: () => import('@/pages/PersonalCenter/CompInfo/InnovationOutput'),
  },
  {
    path: '/personalCenter/compInfo/environmentInfo', //融资企业信息完善-环保信息
    component: () => import('@/pages/PersonalCenter/CompInfo/EnvironmentInfo'),
  },
  {
    path: '/personalCenter/financialOrgInfo', //金融机构信息完善
    component: () => import('@/pages/PersonalCenter/FinancialOrgInfo'),
  },
  {
    path: '/personalCenter/individualInfo', //个人信息完善
    component: () => import('@/pages/PersonalCenter/IndividualInfo'),
  },
  {
    path: '/personalCenter/riskCompensationApply', //风险补偿申请
    component: () => import('@/pages/PersonalCenter/RiskCompensation/RiskCompensationApply'),
  },
  {
    path: '/personalCenter/riskCompensationExamine', //风险补偿申请
    component: () => import('@/pages/PersonalCenter/RiskCompensation/RiskCompensationExamine'),
  },
]

const router = (props) => (
  <ConfigProvider locale={zhCN}>
    <Router history={history} forceRefresh={!supportsHistory}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={isMobile() ? "/m/index" : "/home"} />} />

        {routes.map(({ path, component }, index) => (
          <Route
            key={index}
            exact
            path={path}
            component={AsyncComponent(component)}
          />
        ))}

        <Route
          path="/personalCenter"
          render={() => (
            <PersonalCenter>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/personalCenter/userInfo" />}
                />
                {personalRoutes.map(({ path, component }, index) => (
                  <Route
                    key={index}
                    exact
                    path={path}
                    component={AsyncComponent(component)}
                  />
                ))}
                <Route
                  component={AsyncComponent(() => import('@/pages/404/404'))}
                />
              </Switch>
            </PersonalCenter>
          )}
        />

        <Route component={AsyncComponent(() => import('@/pages/404/404'))} />
      </Switch>
    </Router>
  </ConfigProvider>
)

export default router
