/**
 * Created by fanbo on 2021/6/23.
 */
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { message, Menu, Modal, Image, Avatar } from "antd";
import { API } from "@/utils/API";
import { GET, POST } from "@/utils/service";
import * as types from '@/utils/constants'
import * as ActionTypes from '@/utils/constants'
import SiderMenu from './SiderMenu'
import page403 from '../../pages/403/403'
import './PersonalCenter.less'
import { UserOutlined } from '@ant-design/icons';

const { SubMenu } = Menu

class PersonalCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navs: [{
                name: '首页',
                link: '/home'
            }, {
                name: '金融服务',
                link: '/financialService'
            }, {
                name: '服务体系',
                link: '/serviceSystem'
            }, {
                name: '精准惠企',
                link: '/policyGuidance'
            }, {
                name: '股债联动',
                link: '/stockBondLink'
            }, {
                name: '新闻公告',
                link: '/news'
            }, {
                name: '平台介绍',
                link: '/platformIntroduction'
            }],
            isExist: true,
            token: props.user.token
        };
    }

    componentDidMount() {
        const { app, user, location } = this.props

        if (user.isLogin) {
            this.getPCMenu()
            this.getUnReadList()
        } else {
            this.props.history.push('/login')
        }

    }

    componentWillReceiveProps(nextProps) {
        const { user = {}, location } = this.props
        const pathname = location.pathname

        if (pathname == '/login') {
            return
        }
        if (user.isLogin && nextProps.user.userInfo.companyId != user.userInfo.companyId) {
            this.getUserInfo()
        }
    }

    // static getDerivedStateFromProps(nextProps,prevState){
    //     if (nextProps.user.token !== prevState.token){
    //         window.location.reload()
    //     }
    //     return null
    // }

    getPCMenu = () => {
        const { user, dispatch } = this.props
        GET(API.pcMenu, {
            userId: user.userInfo.userId
        }).then(res => {
            if (res.code == 200) {
                this.setState({
                    menuList: res.data || []
                })
                dispatch({
                    type: types.MENULIST,
                    data: res.data
                })
                this.getKeys(res.data)
                this.isPathExist(res.data)
            } else {
                message.error(res.msg)
            }
        })
    }

    isPathExist = (menuList) => {
        const path = this.props.location.pathname || ''
        console.log('path:', path)
        let isExist = false

        for (let i = 0; i < menuList.length; i++) {
            let groupItem = menuList[i]
            for (let j = 0; j < groupItem.children.length; j++) {
                let item = groupItem.children[j]
                if (item.children && item.children.length > 0) {
                    for (let k = 0; k < item.children.length; k++) {
                        let kItem = item.children[k]
                        if (kItem.path == path) {
                            isExist = true
                            break
                        }
                    }
                } else {
                    if (item.path == path) {
                        isExist = true
                        break
                    }
                }

            }
        }

        if (!isExist) {
            this.props.history.push('/personalCenter/userInfo')
        }

        // this.setState({
        //     isExist
        // })
    }

    getUnReadList = () => {
        GET(API.selectUnreadList, {
            userId: this.props.user.userInfo.userId,
            source: 1,
        }).then(res => {
            if (res.code == 200) {
                const data = res.data
                this.setState({
                    unReadList: data.content,
                    unPageNum: data.pageNum,
                    unTotal: data.totalSize
                })
            } else {
                message.error(res.msg)
            }
        })
    }


    menuClick = (index, item) => {
        this.props.history.push(item.link)
    }

    getKeys = (menuList) => {
        const { dispatch, app } = this.props

        let href = window.location.href
        let hrefArr = href.split('#')
        if (hrefArr.length == 2) {
            let path = hrefArr[1]

            for (let i = 0; i < menuList.length; i++) {
                let item = menuList[i]
                for (let j = 0; j < item.children.length; j++) {
                    let subMenuItem = item.children[j]

                    if (path.indexOf(subMenuItem.path) != -1) {

                        this.setState({
                            menuKey: [subMenuItem.parentId],
                            subMenuKey: [subMenuItem.menuId]

                        })
                        dispatch({ type: types.SELECTOPEN, data: [subMenuItem.parentId] || [] })
                        dispatch({ type: types.SELECTMENU, data: [subMenuItem.menuId] || [] })
                        return {
                            menuKey: subMenuItem.parentId,
                            subMenuKey: subMenuItem.menuId
                        }
                    }

                }
            }

        }
    }


    logout = () => {
        const { user, dispatch, history } = this.props
        Modal.confirm({
            content: '确定退出登录吗?',
            okText: '退出',
            cancelText: '取消',
            onOk() {
                dispatch({ type: ActionTypes.LOGOUT })
                history.push('/login')
            }
        });

    }

    redDotClick = () => {
        this.props.history.push('/messageList')
    }

    chatClick = () => {
        this.props.history.push('/chat')
    }

    switchComp = (item) => {
        const { dispatch } = this.props
        POST(API.changeComp, {
            compUuid: item.uuid
        }).then(res => {
            if (res.code == 200) {
                this.setState({
                    isShowSwitchCompModal: false
                })
                dispatch({
                    type: ActionTypes.LOGIN,
                    data: res.data.access_token
                })
                this.getUserInfo()
            } else {
                message.error(res.msg)
            }
        })
    }


    getUserInfo = () => {
        const { dispatch, history } = this.props;

        GET(API.getUserInfo).then(res => {
            if (res && res.code == 200) {
                dispatch({
                    type: ActionTypes.GET_USER_INFO,
                    data: res.data.portalUser,
                });
                window.location.reload()
            }
        });
    };

    onSwitchCompCancel = () => {
        this.setState({
            isShowSwitchCompModal: false
        })
    }


    render() {

        const { menuKey, subMenuKey, navs, unTotal = 0, isExist, isShowSwitchCompModal } = this.state

        const { user } = this.props
        const { userInfo = {}, compList = [] } = user;

        return (
            <div>
                <div className="rsc header-nav-bg">
                    <Link to="/home">
                        <img src={require('../../assets/images/logo.png').default} style={{ height: 40 }} />
                    </Link>
                    <div className="rc" style={{ flex: 1, height: 50, margin: '0 5rem' }}>
                        {
                            navs.map((item, index) => {
                                return <div className="nav-item" key={index + '_nav'}
                                    onClick={() => this.menuClick(index, item)}>{item.name}</div>
                            })
                        }
                    </div>
                    <div className="rc">
                        <Menu
                            // onClick={this.handleClick}
                            // selectedKeys={[this.state.current]}
                            mode="horizontal"
                        >
                            <SubMenu key="SubMenu" title={
                                <div style={{ minWidth: 160, width: '100%' }} className="rcc">
                                    <UserOutlined />
                                    {/* <div style={{ marginLeft: 10 }}>{userInfo.userName ? userInfo.userName : ''}</div> */}
                                    <div style={{ marginLeft: 10 }}>{userInfo.userName ? userInfo.userName : userInfo?.tel ? userInfo.tel : ''}</div>

                                </div>
                            }>
                                {
                                    (compList && compList.length > 1) && <Menu.Item>
                                        <div style={{ textAlign: 'center' }} onClick={() => {
                                            this.setState({
                                                isShowSwitchCompModal: true
                                            })
                                        }}>切换企业</div>
                                    </Menu.Item>
                                }

                                <Menu.Item>
                                    <div style={{ textAlign: 'center' }} onClick={this.logout}>退出登录</div>
                                </Menu.Item>
                            </SubMenu>

                        </Menu>
                        <div className="redDotPC" onClick={this.redDotClick} style={{ marginRight: '2rem' }}>{unTotal}</div>
                    </div>
                </div>
                <div className="g-global"
                    onClick={this.onClick} onScroll={this.onScroll} onKeyDown={this.onKeyDown}>
                    <div className="m-sider" style={{ width: 256 }}>
                        <div className="m-siderwapper">
                            <div className="m-top rcc">
                                <div className="r">
                                    <Avatar src={userInfo.avatar + '?t=' + Date.parse(new Date())} className="m-avatar" size={64} icon={<UserOutlined />} alt="" />
                                    <div>
                                        <div className="m-name">{userInfo.userName || userInfo?.tel || ''}</div>
                                        {/* <div className="m-label">{userInfo.positionStr}</div> */}
                                        <div className="m-comp-name">{userInfo.comp && userInfo.comp.compFullName}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-menulist" style={{ width: 256 }}>
                                <SiderMenu
                                    {...this.props}
                                    menuKey={menuKey}
                                    subMenuKey={subMenuKey}
                                />
                            </div>
                        </div>


                    </div>
                    <div className="m-main" style={{ marginLeft: 256 }}>
                        <div className="m-container">
                            <div className="pc-container">
                                <div className="w0">
                                    <div className="pc-block">
                                        {
                                            isExist ? this.props.children : <div className="pnf-wrapper">
                                                <div className="pnf-wrapper--title">4 0 3</div>
                                                <div className="pnf-wrapper--subtitle"> <strong>:(</strong>抱歉，你无权访问该页面</div>
                                                <div className="pnf-wrapper--tip">返回<Link to="/">首页</Link></div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Modal
                    title={'选择企业'}
                    destroyOnClose
                    visible={isShowSwitchCompModal}
                    footer={null}
                    onCancel={this.onSwitchCompCancel}
                >
                    <div>
                        {
                            compList && compList.map(v => {
                                return <div className='comp_item' key={v.uuid} onClick={() => { this.switchComp(v) }}>{v.compName || '-'}</div>
                            })
                        }
                    </div>
                </Modal>
            </div>

        )
    }
}


const mapStateToProps = ({ app, user }) => ({ app, user });

export default withRouter(connect(mapStateToProps)(PersonalCenter));