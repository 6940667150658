/**
 * Created by fanbo on 2021/6/23.
 */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, message } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { API } from "../../utils/API";
import { GET, POST } from "../../utils/service";
import * as types from '../../utils/constants'

import { logicCheck } from '@/logic/appLogic'

const { SubMenu, ItemGroup } = Menu;


class SiderMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuList: []
        };
    }

    componentDidMount() {
        this.getPCMenu()
    }

    getPCMenu = () => {
        const { user, dispatch } = this.props
        GET(API.pcMenu, {
            userId: user.userInfo.userId
        }).then(res => {
            if (res && res.code == 200) {
                this.setState({
                    menuList: res.data || []
                })
                dispatch({
                    type: types.MENULIST,
                    data: res.data
                })
                //初始化选择menu menuItem
                let pathname = this.props.location.pathname
                let menuList = res.data || []
                menuList.map((itemSubMenu, index) => {

                    itemSubMenu.children && itemSubMenu.children.map((itemSubMenuItem, i) => {
                        if (itemSubMenuItem.children) {
                            itemSubMenuItem.children.map((v, i) => {
                                if (v.path === pathname) {
                                    this.setState({
                                        subMenu: v.parentId + '',
                                        subMenuItem: v.menuId + ''
                                    })
                                }
                            })
                        } else {
                            if (itemSubMenuItem.path === pathname) {
                                this.setState({
                                    subMenu: itemSubMenuItem.parentId + '',
                                    subMenuItem: itemSubMenuItem.menuId + ''
                                })
                            }
                        }

                    })
                })
            } else {
                message.error(res && res.msg)
            }
        })
    }




    menuClick = (v, item) => {
        const { dispatch, history, user } = this.props
        let path = v.path
        if (!path) {
            message.info('未配置地址')
            return
        }

        if (path.indexOf('compInfo') != -1) {
            let auditStatus = user.userInfo.auditStatus
            if (!auditStatus || auditStatus == null || auditStatus == 0) {
                message.info('您还没有实名认证')
                history.goBack()
                return
            }

            if (parseInt(auditStatus) == 1) {
                message.info('您的实名认证正在审核中')
                history.goBack()
                return
            }

            if (parseInt(auditStatus) == -1) {
                message.info('您的实名认证被驳回')
                history.goBack()
                return
            }

        }

        if (path.indexOf('renewalLoanForm') != -1 && user.userInfo.comp.isBlacklist > 0) {
            message.error('企业已被列入黑名单，不可进行操作')
            return
        }

        // if (path.indexOf('realNameAuth') != -1) {
        //     if (logicCheck(this.props, 2)) {
        //         return
        //     }
        // }

        // if (path.indexOf('compInfo') != -1 || path.indexOf('reportManage') != -1 || path.indexOf('personManage') != -1) {
        //     if (logicCheck(this.props)) {
        //         return
        //     }
        // }


        let isPC = (path.indexOf('personalCenter') != -1)
        if (isPC) {
            dispatch({ type: types.SELECTOPEN, data: [item.keyPath[1]] || [] })
            dispatch({ type: types.SELECTMENU, data: [item.key] || [] })
            //设置选择的menuItem
            this.setState({ subMenuItem: v.menuId + '' })
        }
    }


    menuView = (path, v) => {
        const { user } = this.props
        const { userInfo = {} } = user


        let normalView = <div>{v.menuName}</div>


        if (!path) {
            return normalView
        }

        if (path.indexOf('renewalLoanForm') != -1 && user.userInfo.comp.isBlacklist > 0) {
            return normalView
        }


        let isPC = path.indexOf('personalCenter') != -1
        return <Link
            to={{ pathname: path }}
            target={isPC && path ? '' : '_blank'}
        >
            {normalView}
        </Link>
    }


    render() {

        const { menuKey, subMenuKey, app, user } = this.props


        // if (app.menuList.length == 0) {
        //     return <div />
        // }

        return (
            <div>
                <Menu
                    style={{ width: '100%' }}
                    // openKeys={[this.state.subMenu + '']}
                    selectedKeys={[this.state.subMenuItem + '', this.state.subMenu + '']}
                    mode="inline"
                >
                    {
                        this.state.menuList.map((item, index) => {
                            return <SubMenu key={item.menuId} title={item.menuName}
                                onTitleClick={(values) => {
                                    //设置选择的menu
                                    let { subMenu } = this.state
                                    if (subMenu === values.key) {
                                        //再次点击则关闭
                                        this.setState({ subMenu: '' })
                                    } else {
                                        this.setState({ subMenu: values.key + '' })
                                    }
                                }}>
                                {
                                    item.children.map((v, i) => {
                                        if (v.children && v.children.length > 0) {

                                            return <SubMenu key={v.menuId} title={v.menuName}
                                                onTitleClick={(values) => {
                                                    // //设置选择的menu
                                                    // let { subMenu } = this.state
                                                    // if (subMenu === values.key) {
                                                    //     //再次点击则关闭
                                                    //     this.setState({ subMenu: '' })
                                                    // } else {
                                                    //     this.setState({ subMenu: values.key + '' })
                                                    // }
                                                }}
                                            >
                                                {
                                                    v.children.map((vItem, vIndex) => {
                                                        return <Menu.Item
                                                            key={vItem.menuId}
                                                            onClick={(e) => {
                                                                this.menuClick(vItem, e)
                                                            }}
                                                        >
                                                            {this.menuView(vItem.path, vItem)}

                                                        </Menu.Item>
                                                    })
                                                }
                                            </SubMenu>
                                        }
                                        return <Menu.Item
                                            key={v.menuId}
                                            onClick={(e) => {
                                                this.menuClick(v, e)
                                            }}
                                        >
                                            {this.menuView(v.path, v)}

                                        </Menu.Item>
                                    })
                                }
                            </SubMenu>
                        })
                    }


                </Menu>

            </div>

        );
    }
}


const mapStateToProps = ({ app, user }) => ({ app, user });

export default connect(mapStateToProps)(SiderMenu);