import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import app from '@/store/app'
import user from '@/store/user'
import myown from '@/store/myown'

const reducer = combineReducers({
  app,
  user,
  myown,
})

const middleware = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)))

export default store
