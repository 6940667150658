export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_USER_INFO = 'GET_USER_INFO'
export const COMP_LIST = 'COMP_LIST'

export const LABEL_LIST = 'LABEL_LIST'
export const UN_READ_COUNT = 'UN_READ_COUNT'


export const TOGGLESIDER = 'toggle-sider'
export const LOAD_MENU = 'load-menu'
export const SELECTMENU = 'select-menu'
export const SELECTOPEN = 'select-open'
export const CHANGEOPENMENUKEY = 'change-open-menu-key'
export const ADDMENUROW = 'add-menu-row'
export const UPDATEMENUROW = 'update-menu-row'
export const DELMENUROW = 'del-menu-row'
export const MENULIST = 'menu-list'
